<template>
  <van-nav-bar
    title="TRADER99 Beta1.0"
    left-text="返回"
    fixed="true"
    placeholder="true"
    left-arrow
    @click-left="onClickLeft"
    @click-right="onClickRight"
  />

  <!-- {{today[0]}}
  <hr>
  {{tmp[0]}}
  <hr> -->
  <!-- vuex中的 <br>
  {{local}} -->
  <!-- <hr>
  reactive中的<br>
  {{selectedStocks}} -->
  <!-- <Swiper :local = "local"></Swiper> -->
  <!-- 看起來是deafult 的 local和變更過從vuex傳過來的local 不一樣，所以造成for迴圈不能跑 -->
  <!-- <div  v-for=" item in JSON.parse(local)" :key="item">{{item}}</div> -->
  <van-swipe
    class="my-swipe"
    :autoplay="3000"
    indicator-color="white"
    lazy-render
    @YalpFather="modifySwap"
  >
    <van-swipe-item class="swip_up" v-for="(item, index) in local" :key="item">
      <div v-if="item[1] > 0" class="swip_up">
        <van-icon name="arrow-up"></van-icon> {{ index }}{{ item[0] }}<br />{{
          item[1]
        }}
      </div>
      <div v-else class="swip_down">
        <van-icon name="arrow-down"></van-icon> {{ index }}{{ item[0] }}<br />{{
          item[1]
        }}
      </div>
    </van-swipe-item>
  </van-swipe>
  <router-view />
  <van-tabbar v-model="active">
    <van-tabbar-item
      icon="home-o"
      @click="
        tmpFilter('all');
        sendGa({ action: 'click', category: 'footer', label: 'footer_all' });
      "
      >全部</van-tabbar-item
    >
    <van-tabbar-item
      icon="search"
      @click="
        tmpFilter(10);
        sendGa({ action: 'click', category: 'footer', label: 'footer_up' });
      "
      >漲停</van-tabbar-item
    >
    <van-tabbar-item
      icon="friends-o"
      @click="
        tmpFilter(-10);
        sendGa({ action: 'click', category: 'footer', label: 'footer_down' });
      "
      >跌停</van-tabbar-item
    >
    <van-tabbar-item
      icon="setting-o"
      @click="
        tmpFilter(0);
        sendGa({ action: 'click', category: 'footer', label: 'footer_same' });
      "
      >平盤</van-tabbar-item
    >
    <van-tabbar-item
      icon="setting-o"
      @click="
        tmpFilter('etf');
        sendGa({ action: 'click', category: 'footer', label: 'footer_etf' });
      "
      >排除ETF</van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
import { useStore, mapState, mapActions } from "vuex";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { onMounted, reactive } from "@vue/runtime-core";
import { getMouse } from "./hook/getMouse.ts";
import { sendGaMethods } from "./hook/sendGa.ts";

// import Swiper from "@/components/Swiper";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    let selectedStocks = reactive(JSON.parse(localStorage.getItem("stock")));
    const { x, y } = getMouse();
    sendGaMethods({
      action: "load",
      category: "mainpage",
      label: "mainpage_load",
    });

    // setInterval(() => {
    //   selectedStocks['test'+Math.random()] = 10;
    // }, 1000);
    const onClickLeft = () => {
      sendGaMethods({ action: "click", category: "header", label: "back" });
      router.push({ name: "Home" });
      Toast("返回");
    };
    const onClickRight = () => {
      Toast("大王系統，是一個練習用來跑敏捷的專案<br>這邊之後放搜尋功能");
    };

    const modifySwap = () => {
      console.log("modifySwap data");
    };
    onMounted(() => {
      //抓取api 放到 store (today & tmp)
      fetch("https://hobbygo.net/files/stock.json")
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          store.state.today = result.slice(1);
          store.state.tmp = result.slice(1);
        });

      // 判斷localStorge是否為空
      if (JSON.parse(localStorage.getItem("stock")) === null) {
        localStorage.setItem(
          "stock",
          JSON.stringify({
            "0050": ["元大台灣50", 0],
            "0056": ["元大高股息", 0],
          })
        );
      } else {
        // 修改store  底下 local 資料
        store.state.local = selectedStocks;
      }
    });
    return {
      onClickLeft,
      onClickRight,
      selectedStocks,
      modifySwap,
      x,
      y,
      // test,
    };
  },
  data() {
    return {
      value: "", //搜尋用
      value1: 0,
      value2: "a",
      option1: [
        { text: "漲跌預設排序", value: 0 },
        { text: "漲跌高->低排序", value: 1 },
        { text: "漲跌低->高排序", value: 2 },
      ],
      option2: [
        { text: "價格預設排序", value: "a" },
        { text: "價格高->低排序", value: "b" },
        { text: "價格低->高排序", value: "c" },
      ],
    };
  },
  components: {
    // Swiper,
  },
  computed: {
    ...mapState(["today", "tmp", "local"]),
  },
  watch: {
    value1(val) {
      console.log(val + "------");
      // this.$store.commit("sortByUpAndDown", val);
      this.$store.dispatch("sortByUpAndDown", val);
    },
    value2(val) {
      console.log(val + "------");
      // this.$store.commit("sortByPrice", val);
      this.$store.dispatch("sortByPrice", val);
    },
  },
  methods: {
    ...mapActions(["tmpFilter"]),
    onSearch(val) {
      Toast(val);
    },
    onCancel() {
      Toast("取消");
    },
    sendGa(val) {
      sendGaMethods(val);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 55px;
  text-align: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}

.swip_up {
  background-color: brown;
}
.swip_down {
  background-color: #42b983;
}
</style>
