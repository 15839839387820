<template>
  <div class="item">
    {{ dataInfo[0] }} -- {{ dataInfo[1] }}
    <van-button plain hairline type="primary" @click="doSomething"
      >收藏</van-button
    >
    <br />
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: Array,
  },
  emits: {
    "my-close": null,
  },
  setup(props, content) {
    const doSomething = () => {
      content.emit("my-close");
    };
    return {
      doSomething,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  font-size: 20px;
  line-height: 50px;
}
</style>
