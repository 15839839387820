<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <StockItem
      v-for="item in tmp"
      :key="item"
      :dataInfo="item"
      @click="moveToDeatal(item)"
      @my-close="addToSave(item)"
    ></StockItem>
  </van-list>
</template>

<script>
import { mapState } from "vuex";
import { ref, reactive } from "@vue/reactivity";
import StockItem from "@/components/StockItem.vue";
import { sendGaMethods } from "@/hook/sendGa.ts";
export default {
  name: "Home",
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    let stockData = reactive({});

    function addToSave(val) {
      console.log(val);
      console.log("addToSave");
    }

    const onLoad = () => {
      // console.log(typeof(stockData))
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          list.value.push("台灣" + i);
        }

        // 加载状态结束
        loading.value = false;

        // 数据全部加载完成
        if (list.value.length >= 100) {
          finished.value = true;
        }
      }, 1000);
    };
    return {
      list,
      onLoad,
      loading,
      finished,
      stockData,
      addToSave,
      // stockList,
      // moveToDeatal,
    };
  },
  components: {
    StockItem,
    // StockDetail,
  },
  methods: {
    moveToDeatal(data) {
      // console.log(data)
      const info = data;
      sendGaMethods({ action: "click", category: data[0], label: data[1] });
      this.$router.push({ name: "Detail", params: { info } });
    },
  },
  computed: {
    ...mapState(["tmp"]),
  },
};
</script>
