import {ref , onMounted ,onUnmounted } from "vue";

function getMouse(){
    const x = ref(0);
    const y = ref(0);
    function mousePosition(e) {
      x.value = e.pageX;
      y.value = e.pageY;
    //   console.log(x.value,y.value);
    }

    onMounted(() => {
        window.addEventListener("mousemove",mousePosition);
    });

    onUnmounted(() => {
        window.removeEventListener("mousemove",mousePosition);
    });
    
    return {
        x,
        y,
    }
}

export {getMouse}

