<template>
  證券代號: {{ this.$route.params.info[0] }}<br />
  證券名稱: {{ this.$route.params.info[1] }}<br />
  成交股數: {{ this.$route.params.info[2] }}<br />
  成交金額:{{ this.$route.params.info[3] }}<br />
  開盤價:{{ this.$route.params.info[4] }}<br />
  最高價:{{ this.$route.params.info[5] }}<br />
  最低價:{{ this.$route.params.info[6] }}<br />
  收盤價:{{ this.$route.params.info[7] }}<br />
  漲跌價差:{{ this.$route.params.info[8] }}<br />
  成交筆數:{{ this.$route.params.info[9] }}<br />
  <!-- <stock-detail></stock-detail> -->
  <hr />
  <van-button
    v-if="!checkSave"
    type="primary"
    size="large"
    color="red"
    @click="
      checkSave = true;
      addToFollow(this.$route.params.info);
    "
    >加入追蹤</van-button
  >
  <van-button
    v-else
    type="primary"
    size="large"
    color="gray"
    @click="
      checkSave = false;
      removeFollow(this.$route.params.info);
    "
    >取消追蹤</van-button
  >
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { mapActions } from "vuex";
import { getMouse } from "../hook/getMouse.ts";
// import StockDetail from '@/components/StockDetail.vue'
export default {
  props: {
    // id:String
  },
  components: {
    // StockDetail,
  },
  setup() {
    const router = useRouter();
    const checkSave = ref(false);
    const { x, y } = getMouse();

    onMounted(() => {
      const localStocks = JSON.parse(localStorage.getItem("stock"));
      const stockNum = router.currentRoute.value.params.info[0];
      console.log(stockNum, localStocks, typeof localStocks);
      let result = Object.hasOwnProperty.call(localStocks, stockNum);
      checkSave.value = result;
    });

    return {
      checkSave,
      x,
      y,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["addToFollow", "removeFollow"]),
  },
};
</script>

<style></style>
