import { createStore } from "vuex";
import { sendGaMethods } from "@/hook/sendGa.ts";
// import Stock from './stock'

export default createStore({
  state: {
    today: [],
    tmp: [],
    local: {},
  },
  mutations: {
    addToFollow(state, payload) {
      let localStocks = state.local;
      localStocks[payload[0]] = [payload[1], payload[8]];
      localStorage.setItem("stock", JSON.stringify(localStocks));
      state.local = localStocks;
      sendGaMethods({
        action: "click",
        category: "addfavorite",
        label: payload[0],
      });
      // state.checkSave = true;
    },
    removeFollow(state, payload) {
      let localStocks = state.local;
      delete localStocks[payload[0]];
      localStorage.setItem("stock", JSON.stringify(localStocks));
      state.local = localStocks;
      sendGaMethods({
        action: "click",
        category: "removefavorite",
        label: payload[0],
      });
      // state.checkSave = false;
    },
    tmpFilter(state, payload) {
      // console.log("變更條件",payload);
      // console.log(typeof(payload));
      // let tmpFiltered = [];
      // state.tmp = state.today;
      // console.log(state.today);
      if (!isNaN(payload)) {
        console.log("數字", payload);
        if (payload == 0) {
          // console.log("==0");
          state.tmp = state.today.filter(function (value) {
            return value[8] == payload;
          });
        }
        if (payload >= 10) {
          state.tmp = state.today.filter(function (value) {
            return value[8] >= payload;
          });
        }
        if (payload <= -10) {
          state.tmp = state.today.filter(function (value) {
            return value[8] <= payload;
          });
        }
        console.log("數字過濾結果", state.tmp);
      } else {
        console.log("非數字", payload);
        if (payload == "all") {
          state.tmp = state.today;
        }
        if (payload == "etf") {
          state.tmp = state.today.filter(function (value) {
            return (
              String(value[0]).slice(0, 2) != "00" &&
              String(value[0]).length == 4
            );
          });
        }
      }
    },
    sortByPrice(state, payload) {
      console.log("價格排序", payload);
    },
    sortByUpAndDown(state, payload) {
      console.log("漲跌排序", payload);
    },
  },
  actions: {
    addToFollow({ commit }, payload) {
      // console.log("addToFollow", payload);
      commit("addToFollow", payload);
    },
    removeFollow({ commit }, payload) {
      // console.log("removeFollow", payload);
      commit("removeFollow", payload);
    },
    tmpFilter({ commit }, payload) {
      commit("tmpFilter", payload);
    },
    sortByPrice({ commit }, payload) {
      console.log("sortByPrice action");
      commit("sortByPrice", payload);
    },
    sortByUpAndDown({ commit }, payload) {
      console.log("sortByUpAndDown action");
      commit("sortByUpAndDown", payload);
    },
  },
  getters: {},
  modules: {},
});
