// 方法的形式
function sendGaMethods(val) {
    console.log("send to ga",val);
    
    window.gtag(
        "event",
        val.action,
        {
            "event_category" : val.category,
            "event_label" : val.label
        }
    );
}



export {sendGaMethods}